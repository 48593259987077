<template>
	<div class="home">
		<div class="hugeScore" :class="{'good ': score.score >= 0, 'bad ': score.score < 0}">Your Clever Score Is {{ parseInt(score.score).toLocaleString() }}</div>
		<div class="subHeader" v-if="score.numChoices">You've made {{ parseInt(score.numChoices).toLocaleString() }} choices
			and were correct about {{ parseInt(score.numCorrect).toLocaleString() }} of them ({{ score.percentage }}%)</div>
		<fieldset
							class="group"
							v-for="(group, index) in groups"
							v-bind:key="'group' + index"
							@click="goTo(group)"
							:style="$services.hasKey('Community') ? 'cursor:pointer' : ''">
			<legend class="groupName">{{ group.name }}</legend>

			<div v-if="group.myScore && group.myScore.numChoices > 0" class="groupScore right" :class="{'good ': group.myScore.score >= 0, 'bad ': group.myScore.score < 0}">
				{{ parseInt(group.myScore.score).toLocaleString() }}
			</div>
			<div v-else><i>You have't scored yet.</i></div>

			<!-- User is the only member of the group -->
			<div v-if="group.details.users.length == 1">
				You've been correct about {{ group.myScore.percentage }}% of your choices: {{ group.myScore.numCorrect }} of {{ group.myScore.numChoices }}.
			</div>
			<div v-if="group.myScore && group.myScore.numChoices > 0">
				<div v-if="group.place > 0"><b>You are ranked #{{ group.place + 1 }} of {{ group.details.users.length }}</b></div>
			</div>
			<div>
				<img style="float: left; height: 25px;background-color: #efeff5; padding: 1px; margin: 0px 4px 0px 2px; border-radius: 3px;"
						class="actorImage" :src="group.details.users[0].bestActor.imageURL">
				<b :style="`color:${group.details.users[0].color}`">{{ group.place == 0 ? 'You' : group.details.users[0].userName }}</b>
				{{ group.place == 0 ? 'are' : 'is' }}
				the group expert, scoring {{ parseInt(group.details.users[0].score.score).toLocaleString() }}
				by effectively picking <b>{{ group.details.users[0].bestActor.useThe ? 'the' : '' }} {{ group.details.users[0].bestActor.name }}</b>
				<spark-line v-if="group.details.users[0].bestActor.numChoices > 1" class="sparkline" :scoreLines="[{color: '#0095e6', scores: group.details.users[0].bestActor.scores}]"></spark-line>
				<span v-if="group.details.users[0].bestInfluencer && group.details.users[0].bestInfluencer.score >= 0">
						and being most positively influenced by <b>{{ group.details.users[0].bestInfluencer.name }}</b>
						<spark-line v-if="group.details.users[0].bestInfluencer.numChoices > 1" class="sparkline" :scoreLines="[{color: '#0095e6', scores: group.details.users[0].bestInfluencer.scores}]"></spark-line>
					</span>
			</div>
		</fieldset>

		<div class="resultHeader">Your Recent Results</div>
		<choice-row :choice="choice" v-for="choice in results" v-bind:key="choice.id"></choice-row>
		<div class="bottom-spacer" />
	</div>
</template>

<script>
import ChoiceRow from '@/components/ChoiceRow.vue'
import SparkLine from "@/components/SparkLine";

export default {
	name: "Basic",
	components: {
		ChoiceRow,
		SparkLine
	},
	props: ["id"],
	data() {
		return {
			colors: ["#0095e6", "#ea9770", "#ffd670", "#70d6ff", "#6a2317", "#ca1bad", "#3f0dc4", "#fecda4", "#2bff70", "#ff7006", "#008800", "#ff0000", "#badca1", "#654321", "#789abc", "165f9d", "#b6af6d", "#1a535c"],
			results: [],
			groups: [],
			score: {score: 0}
		};
	},
	created() {
		this.loadData();
	},
	methods: {
		loadData: async function () {
			this.results = JSON.parse(localStorage.getItem('score-choice-list'));
			this.groups = JSON.parse(localStorage.getItem('score-group-list'));
			this.loadGroups();
			this.loadChoices();
			this.score = await this.$services.getOne("score", "");
		},

		loadGroups: async function () {
			let groups = await this.$services.getAll("groups");
			const myId = this.$services.me.id;
			groups = groups.map(async (group) => {
				group.details = await this.$services.get(`groups/${group.id}/details`);
				group.place = group.details.users.findIndex(u => u.id == myId);
				group.myScore = group.place > -1 ? group.details.users[group.place].score : {score: {score: 0, numChoices: 0, numCorrect: 0, percentage: 0}};
				console.log("Group is", group.id, group.name, group);
				return group;
			});
			Promise.all(groups).then(res => {
				this.groups = res;
				localStorage.setItem('score-group-list', JSON.stringify(this.groups));
			});
		},

		loadChoices: async function () {
			this.results = await this.$services.get("choices/settled");
			localStorage.setItem('score-choice-list', JSON.stringify(this.results));
		},
		goTo: async function (group) {
			if (this.$services.hasKey("Community")) {
				this.$router.push(`/group/${group.id}`)
			}
		}
	}
}
</script>

<style scoped>
.hugeScore {
	font-size: 35px;
	text-align: center;
	font-weight: 600;
}

.subHeader {
	font-size: 20px;
	text-align: center;
}

.group {
	background-color: black;
	color: #eeeeee;
	display: block;
	clear: all;
	border: 2px solid #7ab3eb;
	border-radius: 4px;
	margin: 3px;
	padding: 3px;
	font-size: 18px;
}

.groupName {
	color: #99c4f0;
	font-size: 22px;
}

.groupScore {
	font-size: 30px;
	font-weight: 600;
	padding-right: 3px;
	vertical-align: top;
	display: inline-block;
}

.groupSubHeader {
	font-size: 18px;
}

.leaderSection {
	font-size: 18px;
	margin-top: 5px;
	background-color: rgba(51, 153, 255, 0.2);
	border-radius: 5px;
	border: 1px solid black;
	padding: 2px;
}

.resultHeader {
	font-size: 22px;
	background: #444444;
	margin: 5px 0px 2px 0px;
	border-top: 1px solid yellow;
	color: white;
	padding: 2px;
}

.actorImage {
	float: left;
	height: 25px;
	background-color: #efeff5;
	padding: 1px;
	margin: 0px 4px 0px 2px;
	border-radius: 3px;
}
</style>
